<template>
  <div>
    <dialog-edit :value="value" @input="$emit('close')" maxWidth="1200px" @keydown.esc="$emit('input')">
      <template v-slot:title> {{ data.title }} </template>
      <v-row>
        <v-col cols="12" sm="6" class="px-2">
          <material-card color="primary">
            <template v-slot:heading>
              <div class="px-0 py-0">Объект</div>
            </template>
            <a-form-view :value="data" :model="modelLeft" :config="{ dense: true, hideNull: !true }" @click="onClick($event)" />
          </material-card>
        </v-col>
        <v-col cols="12" sm="6" class="px-2">
          <material-card color="primary">
            <template v-slot:heading>Собственник</template>
            <a-form-model v-model="data" :model="modelRight" :errors="errors" :config="config" @validate="validate($event)" class="px-4" />
          </material-card>
        </v-col>
        <v-col cols="12" class="px-3">
          <material-card color="primary">
            <template v-slot:heading>Информация из объявления</template>
            <v-btn v-if="data.vendor_id && !isInfo" @click="getInfo" :loading="isInfoProcess">
              Загрузить.
              <template v-slot:loader>
                <span>Загрузка...</span>
              </template>
            </v-btn>
            <a-form-model v-if="m && isInfo" v-model="data" :model="calcModel('detail1')" :errors="errors" :config="config" @validate="validate($event)" class="px-4" />
          </material-card>
        </v-col>
        <v-col cols="12" class="px-3">
          <material-card color="primary">
            <template v-slot:heading>
              <div class="px-3 py-0 d-flex flex-wrap" style="justify-content: space-between">
                <div class="align-self-center">Фото без водяных знаков</div>
                <div>
                  <v-btn v-if="data.images && data.images.length" :href="$root.config.imageUrl + 'all/' + id" text>
                    <v-icon @click1="onSaveAll()" class="mr-2">fas fa-save</v-icon>
                    скачать архив
                  </v-btn>
                </div>
              </div>
            </template>
            <v-btn v-if="data.vendor_id && !isPhoto" @click="getPhotoUnmark" :loading="isInfoProcess">
              Получить фото
              <template v-slot:loader>
                <span>Загрузка...</span>
              </template>
            </v-btn>

            <a-view-images v-if="isPhoto" :value="data.images" />
          </material-card>
        </v-col>
      </v-row>
      <div v-if="loading" class="loading-block">
        <div class="loader"></div>
      </div>
      <confirm-dialog ref="confirmDialog"></confirm-dialog>

      <a-loader v-if="loading" />
      <template v-slot:actions>
        <v-btn v-if="$root.profile.role == 10001 && getAccess('object.print')" :href="getPreview()" target="_blank" class="mx-1" dark color="info" title="PDF">
          <v-icon dark>fas fa-eye </v-icon>
        </v-btn>
        <v-btn v-if="$root.profile.role == 10001" :href="getPreview('html')" target="_blank" class="mx-1" dark color="info" title="PDF">
          <v-icon dark>fas fa-file-code </v-icon>
        </v-btn>
        <v-btn v-if="getAccess('myOffer.create')" :disabled="!isPhoto || !isInfo" @click="makeOffer" color="success" title="Создать предложение">
          <v-icon dark>fas fa-hand-holding-medical </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="id" @click="removeDialogShow = true">
          <v-icon>far fa-trash-alt</v-icon>
          <btn-title v-if="$vuetify.breakpoint.smAndUp">Удалить</btn-title>
        </v-btn>
        <v-btn @click="_submit()" title="">
          <v-icon>far fa-save</v-icon>
          <btn-title v-if="$vuetify.breakpoint.smAndUp">Сохранить</btn-title>
        </v-btn>
        <v-btn @click="$emit('close')">
          <v-icon>far fa-window-close</v-icon>
          <btn-title v-if="$vuetify.breakpoint.smAndUp">Закрыть</btn-title>
        </v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
    <s-edit-form
      v-model="showEditDialog"
      v-if="showEditDialog"
      :m="mOffer"
      :configForm="configFormOffer"
      :initData="configFormOffer.initData"
      @onSave="$emit('makeMyOffer', $event)"
    />
  </div>
</template>

<script>
//import parser from "./../parser";

import { autoHeightBlock, getAccess, popupMenu, genModel, getForm, submitForm, removeEl } from "../../components/mixings";
export default {
  mixins: [autoHeightBlock, getAccess, popupMenu, genModel, getForm, submitForm, removeEl],
  components: {},
  props: {
    idShow: Number,
    value: Boolean,
  },
  data() {
    return {
      //  id: 0,
      loading: false,
      title: "",
      m: this.$store.getters["config/get"].models.object,
      mOffer: this.$store.getters["config/get"].models.myOffer,
      showEditDialog: false,
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить запись?",
      blockHeight: 256,
      isInfoProcess: false,
      configFormOffer: null,
    };
  },
  created() {
    //this.$root.title = this.m.title;
    this.loading = true;
    if (this.id) this.updateData(this.id);
  },
  mounted() {},

  computed: {
    isInfo() {
      let res = false;
      if (this.data?.description || (this.data?.ads_info?.data && this.data?.ads_info?.data?.resp.status !== 429)) res = true;
      return res;
    },
    isPhoto() {
      if (this.data?.images && this.data.images.length) return true;
      return false;
    },
    id() {
      return this.idShow || Number(this.$route.params.id) || 0;
    },
    url() {
      return this.m.url;
    },
    api() {
      return this.m.url;
    },
    modelLeft() {
      let model = this.calcModel("viewForm1");
      return model;
    },
    modelRight() {
      let model = this.calcModel("viewForm2");
      return model;
    },
    model: {
      get() {
        let config = JSON.parse(JSON.stringify(this.m?.config.default || {}));
        let model = this.calcModelConfig(config);
        return model;
      },
    },
    config() {
      return { dense: true, readonly: false, hideDetails: "auto" };
    },
    configForm() {
      return this.m.config.default;
    },
  },
  watch: {
    value(v) {
      if (v) {
        this.updateData(this.id);
      }
    },
    showEditDialog() {
      if (!this.showEditDialog) {
      }
    },
  },
  methods: {
    makeOffer() {
      let data = Object.assign({}, this.data);
      delete data.id;
      delete data.updatedon;
      delete data.updatedby;
      data.object_id = this.data.id;
      data.data = { offer: Object.assign({}, this.data) };
      const configForm = {};
      configForm.type = "create";
      configForm.initData = data;
      configForm.initDataMode = "edit";
      this.configFormOffer = configForm;
      this.showEditDialog = true;
    },

    getPreview(type = "pdf") {
      if (type == "pdf") return this.$root.config.baseUrl + `/unrealty/object/print/${this.id}?token=` + localStorage.getItem("user-token");
      return this.$root.config.baseUrl + `/unrealty/object/printH/${this.id}?token=` + localStorage.getItem("user-token");
    },
    _submit() {
      if (this.readonly) return;
      const fields = this.configForm?.fieldsPost || "";
      console.log(this.configForm, fields);
      this.submit(false, fields);
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("close");
    },
    itemShowClose() {
      if (this.idShow) this.$emit("close");
      else this.$router.push({ name: this.$route.path.split("/")[1] });
    },

    onClick(e) {
      if (e.field?.isMenu) {
        this.showPopupMenu(e.field.name, e.row, e.event, e);
      }
    },

    async getInfo() {
      if (this.isInfoProcess) return;
      this.isInfoProcess = true;
      let url;
      if (this.data.vendor == "avito") url = "https://avito.ru" + this.data.url;
      if (this.data.vendor == "cian") url = this.data.url;

      let info = await this.$axios.post("/offer/getFullInfo", { vendor_id: this.data.vendor_id, vendor: this.data.vendor, url });
      if (info.data.status == "ok") {
        let d = info.data.data;
        this.data.ads_info = d;
        if (d.description) {
          this.data.description = d.description;
        }
      }
      console.log(info);
      this.isInfoProcess = false;
      if (this.id) {
        let ads_info = Object.assign({}, this.data.ads_info);
        ads_info.data = null;
        let data = { id: this.id, ads_info, description: this.data.description };
        await this.saveOffer(data);
      }
    },
    onSaveAll() {
      this.$axios.get(this.url + "/downloadAll/" + this.id);
    },
    async getPhotoUnmark() {
      if (this.isInfoProcess) return;
      this.isInfoProcess = true;
      let url;
      if (this.data.vendor == "avito") url = "https://avito.ru" + this.data.url;
      if (this.data.vendor == "cian") url = this.data.url;

      let info = await this.$axios.post("/offer/getPhotoUnmark", { url });
      if (info.data.status == "ok") {
        let d = info.data.data;
        this.data.images = d;
      }
      this.isInfoProcess = false;
      if (this.id) {
        let data = { id: this.id, images: this.data.images };
        await this.saveOffer(data);
      }
    },
    async saveOffer(data) {
      try {
        let response = await this.$axios.post(this.url, data);
        let status = response.data.status == "ok";
        if (response.data.data) {
          this.$root.$emit("show-info", {
            text: "Данные записаны",
          });
        }
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при сохранении",
        });
      }
    },
  },
};
</script>
